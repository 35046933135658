
body.front {

  #global-search-widget {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  @media #{$tablet} {
    height: $hero-banner-image-height;
    #global-search-widget {
      padding-top: 60px;
    }
  }
  .pane-title {
    font-size: $font-size-h4;
    margin-bottom: 20px;
  }
  .pane-content {
    padding-top: 20px;
    margin-top: 15px;
    border-top: 1px solid $gray-medium;
  }
  .panel-separator {
    height: 15px;
  }
}

.view-browse-by-partner-institution,
.view-other-partner-institutions {
  .list-inline .views-field-field-pi-logo .field-content {
    padding: 5px;
    margin-bottom: 15px;
    a img {
      padding: 10px;
      margin-right: auto;
      margin-left: auto;
      &:hover {
        box-shadow: 0 0 4px $link-hover-color;
      }
    }

    @media #{$tablet} {
      //padding: 15px;
      height: 186px;
      //border-radius: 8px;
      a {
        padding: 15px;
        height: 100%;
        display: block;
        position: relative;
        border: 1px solid $table-border-color;
        &:hover {
          box-shadow: 0 0 4px $link-hover-color;
        }
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: inherit;
          &:hover {
            box-shadow: none;
          }
        }

      }
    }
  }
}

.node-islandora-solr-content-type.node-teaser {

  .title-teaser h2 {
    margin-top: 0px;
    //font-size: $font-size-h4;
  }
  .row {
    margin-bottom: 15px;

    &.institution-logos {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
  .right img {
    border: $accent-low 1px solid;
    border-radius: 4px;
  }
  .field-name-field-hero-image img.img-responsive {
    width: 100%;
  }
  //@media #{$tablet} {
  //  .left .row {
  //    margin-right: 15px;
  //  }
  //}
}
