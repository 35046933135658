//.vertical-center {
//  display: inline-block;
//  vertical-align: middle;
//  float: none;
//}

.region-content, #block-islandora-solr-basic-facets, .modal-body {
  a {
    border-bottom: 1px dotted $link-color;
    padding-bottom: .09em;
    &:hover {
      color: $yu_reverse_link !important;
      border-bottom-color: $yu_reverse_link !important;
    }
  }
  a.btn, .webform-link a {
    border-bottom: none;
    padding-bottom: 6px;
  }
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6,
  .solr-search-controls, .pager, .solr-thumb {
    a {
      border-bottom: none;
    }
  }
}


.row.vertical-align-bottom {
  font-size: 0;
  & > * {
    float: none;
    display: inline-block;
    font-size: $font-size-base;
  }
}

.d-inline-block {
  display: inline-block;
}

.menu.navbar-nav {
  //margin: ($navbar-padding-vertical / 2) (-$navbar-padding-horizontal);

  > li.first > a {
    padding-left: 0;
  }
}

body {
  > .container, > .container-fluid > .container {
    max-width: 940px;
  }
  .front {
    h1{
        font-size: 22px;
    }
  }
}

// Global - Header styles
#page-header-container {
  background: $color_1;
  color: $yu_body_link;
  padding-top: 15px;
  padding-right:0;
  padding-left:0;
  .header.container {
    a {
      color:$yu_body_link;
      &:hover {
        color:$yu_reverse_active_link;
      }
    }
    padding-bottom:10px;
    > .row {
      font-size: 0;
      position: relative;
      height:120px;
      @media (max-width: $screen-sm) {
        height:150px;
      }
      @media (max-width: 540px) {
        height:180px;
      }
      > * {
        position: absolute;
        bottom: 15px;
        float: none;
        display: inline-block;
        font-size: $font-size-base;
      }
      .logo-title {
        left: 0;
        @media (max-width: $screen-sm) {
          position:relative;

        }
      }
      .user-links {
        right: 0;
        @media (max-width: $screen-sm) {
          position:relative;
          margin-right:auto;
          margin-left:auto;
          margin-top:15px;
        }
      }
    }
    @media (max-width: $screen-sm) {
    }
    @media (max-width: 540px) {
      .logo-title .logo {
        display: block;
      }
    }

  }
  .logo-title-link {   
    width: 500px;
    height: 75px;
    display:block;
  }
  .logo-container {
    top: -10px;
    position: relative;
    margin-left:-15px;
    display:flex;
    flex-direction: row;
    flex-wrap:nowrap;
    .wc-logo-left {

    }
    .logo-subtitle {
      font-family: $headings-font-family;
      font-size: 16px;
      font-weight: 200;
      display: inline-block;
      margin-left:10px;
    }
  }
  .main-nav {
    border-top: none;
    background-color: $color_2;
    max-width:100%;
    width:100%;
    a {
      color: $yu_body_link;
      &:hover {
        color: $yu_reverse_link;
      }
    }
    > .row {
      max-width:940px;
      margin-left:auto;
      margin-right:auto;
      ul.navbar-nav li > a {
	font-size: 20px;
        color: $color_5;
        top: -5.5px;
        &.active {
          color:$yu_reverse_active_link;
        }
        &:hover{
          color: $yu_reverse_link;
        }
      }
    }


  }
  .user-links {
    .user-info {
      color: $yu_white;
      white-space:nowrap;
      clear:both;
      margin-bottom: 10px;
      margin-top:15px;
      text-align: right;
      @media (max-width: $screen-sm) {
        text-align: center;
        float: none;
      }
    }
    .row-two > * {
      display:inline-block;
      margin-left:auto;
      margin-right: 0;
    }
    .row-two {
      clear:both;
    }
    .btn.logout, .btn.login {
      width:150px;
      clear:both;
      &:hover{
        color: $yu_white;
        background: $color_6;
      }
      
    }
    .row-two .partner-institution-logos {
      img {
        margin-top: 7px;
        margin-bottom: 7px;
      }
    }

    // Mobile adjustments for user links
    @media (max-width: $screen-sm) {
      float: none;
      > * {
        float:none;

      }
      .row-two > * {
        float:none;
        margin-left: auto;
        margin-right: auto;
      }
    }

  }

  .user-links {

    .user-info, .why-log-in {
      font-weight: bold;
      a {
        color: $yu_white;
	&:hover{
            color: $yu_reverse_link !important;
        }      
      }
    }

    a.login, a.logout {
      color: $yu_white;
      min-width:150px;
      text-align:center;
    }
  }
}

body > .main-container {
  margin-top: 15px;
  .page-header {
    border-bottom: none;
    font-size: 29px;
  }

}

.node > .content > * {
  margin-bottom: 20px;
}

.field.field-name-isct-featured-content-area.field-type-text-long.field-label-hidden {
  padding: 10px;
  border: 1px solid $gray-medium;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;
}

#global-search {
  background: $accent-low;
  -webkit-box-shadow: inset 0 0 3px #BBB8B2;
  box-shadow: inset 0 0 3px #BBB8B2;
  position: relative;
  padding: 0;
  //margin-bottom:20px;
  //height: 90px;
  #global-search-widget {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.hero {

    background-color: transparent;
    height: 422px;

    @media (max-width: $screen-md) {
      height: $hero-banner-image-height;
    }

    #global-search-widget {
      padding-top: 89px;
      position: relative;
    }
  }
  > .container {
    height: 100%;
    position: relative;
  }
  .feature-caption {
    position: absolute;
    bottom: ($grid-gutter-width/2);
    text-shadow: 1px, 1px, 1px, #000000;
    color: #FFFFFF;
    a {
      color: #FFFFFF;
      &:hover {
        color: #FFFF88;
      }
    }
    h1, h2, h3, h4, h5, h6 {
      color:#ffffff;
    }
  }
}

body > footer {
  background-color: $color_6;
  min-height:100px;
  padding-top: 35px;
  margin-top:40px;
  border: none;
  // Footer colors are backwards!
  color: $yu_white;
  a {
    color: $yu_white;
    &:hover {
      color: $yu_reverse_active_link;
    }
  }
  #block-block-1{
    padding: 10px;
    float: left;
    margin-right: 10px;
    p{
      font-size: 15px;
      line-height: 18px;
      font-weight: 100;
    }
  }
  #block-block-2{
    padding: 10px;
    float: left;
    p{
      font-size: 15px;
      line-height: 18px;
      font-weight: 100;
    }  
  }
  #block-menu-menu-footer-menu{
    float: left;
    ul{
      text-align: left;
      li{
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        text-align: left;
        float: left;
        a{
          font-size: 16px;
          line-height: 15px;
          &:hover{
            background-color: $color_6;
            color: $yu_reverse_link;
          }
        }
      }
    }
  }
}

.feature-caption {
  font-family: $font-family-serif;
  font-style:italic;
  font-size: $font-size-small;
  a {
    font-weight:bold;

  }
}

// Both navbar header and collapse
//
// When a container is present, change the behavior of the header and collapse.

.container,
.container-fluid {
  .col-xs-12.main-container,
  .row .navbar-header,
  .row .navbar-collapse,
  .row #global-search-widget,
  .row section.col-sm-12.content-column,
  .row.content-header > .col-xs-12 {
    @media (min-width: $grid-float-breakpoint) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}

.btn {
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-h5, floor(($font-size-h5 * $line-height-base)), $btn-border-radius-base);
}

.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger
{
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-xsmall, $font-size-xsmall, $btn-border-radius-base);
  //text-transform: uppercase;
  text-shadow: 1px 1px 2px #000;
  font-family: $btn-font-family;
  font-weight: 700;
  letter-spacing: 1px;
  box-shadow: inset -1px -1px 1px 0 #560712, inset 1px 1px 1px 0 rgba(255, 255, 255, 0.7);

  a {
    color: #fff;
  }
}

.form-control:focus {
  border-color: $gray-medium;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(10,10,8, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(10,10,8, 0.3);
}

.node-type-page .field-name-field-show-view .field-item {
  margin-top: 30px;
}

.ellipis {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.vcenter {
  //display: inline-block;
  //vertical-align: middle;
  //float: none;
  display: flex;
  align-items: center;
}

.fa.fa-question-circle {
  font-size: 0;

  &::before {
    font-size: 16px;
  }
}

// In case the modal link block is wrapped in .text-nowrap
.modal div[role="document"] {
  white-space:normal;
  // TODO: figure out how to make the modal-body vertically scroll
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.logo-title {
  white-space: normal;
}

//.logo-title-link {
//  font-family: $headings-font-family;
//  font-size: 24px;
//  font-weight: bold;
//  color: $yu_reverse_link;
//  //white-space: nowrap;
//  //display: inline-block;
//  margin-right: 16px;
//
//  &:hover {
//    color: $yu_reverse_active_link;
//  }
//}

//.logo-subtitle {
//  font-family: $headings-font-family;
//  font-size: 16px;
//  //font-style: italic;
//  //color: #93876F;
//  font-weight: 200;
//  display: inline-block;
//  white-space: nowrap;
//  margin-left:10px;
//  @media (max-width: $screen-md) {
//    display: inline-block;
//    margin-left: 80px;
//  }
//}

.modal-link-wrapper {
  .btn {
    padding-top: 2px;
  }
}

.modal-body-wrapper {
  text-align: left;
}

.user-info.why-log-in {
  .modal-toggle {
    padding-right: 0;
  }
}

ul.pagination, ul.pager {
  li.pager-current {
    color: $link-hover-color;
  }
}
.islandora-solr-simple-search-widget{
  #search_concept{
    top: 4px;
    position: relative;
  }
  .input-group-btn{
    .form-submit{
      color: $color_3;
      background: white;
      border: 1px solid #ccc;
      border-left: none;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
  }
}
.view-collections{
  h2{
    a{
      color: $color_1;
    }
  }
  &.view-display-id-page_2 .view-content {
    display: flex;
    flex-wrap: wrap;
    .views-row{
      flex: 1 0 calc(49% - 15px);
      margin-right: 17px;
      min-height: 360px;
      border: 1px solid $color_3;
      background-color: #002868;
      .views-field-title{
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px; 
      }
      &.views-row-last {
        margin-bottom: 15px;
      }
      img{
        height: 300px;
        width:100%;
        object-fit: cover;
      }
      a{
        font-size: 24px;
        line-height: 32px;
        color: $yu_white;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        flex: 1 0 100%;
        img {
          height: 100%;
        }
        &.views-row-last {
          margin-bottom: 0px;
        }
      }

    }
  }
}

