body.page-node.node-type-participating-institution {
  #global-search {
    margin-top: 30px;
  }
  article {
    .row > .col {
      > .field {
        margin-bottom: 15px;
      }
      .paragraphs-item-contact-block .field-name-field-ci-contact-form.btn {
        margin-top:15px;
      }
    }
  }
  .paragraphs-item-contact-block {
    margin-bottom:15px;
  }
  .view-collections {
    .views-row {
      margin-bottom:30px;
      .field-name-field-hero-image {
        .field-item a img {
          width: 100%;
        }
      }
    }
  }
}

.other-participating-institutions {

  border-top: solid 1px $list-group-border;
  padding-top: 50px;

  h5 {
    text-align: center;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;

    @media #{$tablet} {
      text-align: left;
    }
  }

  .list-inline {
    margin-left: -25px;
    margin-right: -25px;
  }
}

.node-participating-institution {
  .field-name-body {
    @media only screen and (max-width: 767px) {
      margin-top: 15px;
    }
  }
}
