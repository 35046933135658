#global-search-widget {
  padding-top: 20px;
  padding-bottom: 20px;

  .glyphicon-search {
    font-size: 0;

    &::before {
      font-size: 16px;
    }
  }

  .input-group-btn .btn, .form-item-islandora-simple-search-query input.form-control {
    height:40px;
  }
  .input-group.form-group {
    box-shadow: 0px 0px 18px rgba(0,0,0, .5);
    border-radius: 4px;
    margin-right: -1px;
    border: 1px solid ;
      border-top: rgba(200,200,200,.8);
      border-left: rgba(200,200,200,.8);
      border-bottom: 0;
    .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
      margin-right: -1px;
    }
    .form-item input {
      font-size: $font-size-h5;
    }
    .search-panel.form-wrapper ul.dropdown-menu {
      //#islandora-solr-simple-search-form .input-group-btn .btn.dropdown-toggle {
        background-color: #fff;
        border-radius-top-left: 4px;
        border-radius-bottom-left: 4px;
        border: 1px solid $table-border-color;
        z-index: 3;
      //}
      padding: 4px;
      li {
        border: none;
        padding: 4px 4px 4px 4px;
        ul {
          padding: 0 4px;
        }
        a {
          padding: 0;
          color: inherit;
          &:hover, &:active, &:focus {
            text-decoration: none;
          }
        }
      }
      li.divider {
        padding:1px;
      }
      .active {
         a {
          background-color:transparent;
        }
      }
    }
  }

  .islandora-solr-simple-search-widget .input-group-btn .btn.dropdown-toggle {
    overflow: hidden;
    position: relative;
    padding-right: 24px /* Room for caret */;
    text-align: left;
    text-overflow: ellipsis;
    width: calc(100% + 4px);
  }
  .form-item-islandora-simple-search-query input.form-text {
    margin-left: 4px;
  }
  .no-collection-selector {
    background-color: #ffffff;
    .form-item-islandora-simple-search-query input.form-text {
      margin-left: 0px;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
  }
  .islandora-solr-simple-search-widget .input-group-btn button.form-submit {
    font-size:$font-size-base;
  }
  .btn .caret {
    position: absolute;
    right: 8px;
    top: 18px;
  }
  #search-collection-selector {
    max-width: 180px;
  }
  @media (min-width: $screen-xs-min) {
    #search-collection-selector {
      max-width: 240px;
    }
  }
  @media (min-width: $screen-sm-min) {
    #search-collection-selector {
      max-width: 500px;
    }
  }
  @media (min-width: $screen-lg-min) {
    #search-collection-selector {
      max-width: 800px;
    }
  }

}

#search-collection-selector > ul > li.selected {
  opacity:.5;
  pointer-events: none;
}