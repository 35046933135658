*[class*='col-'], .container, .container-fluid {
  &.no-gutter {
    padding-right: 0;
    padding-left: 0;
  }
  &.no-gutter-left {
    padding-left: 0;
  }
  &.no-gutter-right {
    padding-right: 0;
  }
  &.reset-gutters {
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
  }

}
.row {
  &.no-gutter {
    margin-right: 0;
    margin-left: 0;
  }
  &.no-gutter-left {
    margin-left: 0;
  }
  &.no-gutter-right {
    margin-right: 0;
  }
  &.reset-gutters {
    margin-right: ($grid-gutter-width / 2);
    margin-left: ($grid-gutter-width / 2);
  }
}

@media (min-width: $screen-sm-min) {
  *[class*='col-'], .container, .container-fluid {
    &.no-gutter-sm {
      padding-right: 0;
      padding-left: 0;
    }
    &.no-gutter-sm-left {
      padding-left: 0;
    }
    &.no-gutter-sm-right {
      padding-right: 0;
    }
    &.reset-gutters-sm {
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
    }
  }
  .row {
    &.no-gutter-sm {
      margin-right: 0;
      margin-left: 0;
    }
    &.no-gutter-sm-left {
      margin-left: 0;
    }
    &.no-gutter-sm-right {
      margin-right: 0;
    }
    &.reset-gutters-sm {
      margin-right: ($grid-gutter-width / 2);
      margin-left: ($grid-gutter-width / 2);
    }
  }
}

@media (min-width: $screen-md-min) {
  *[class*='col-'], .container, .container-fluid {
    &.no-gutter-md {
      padding-right: 0;
      padding-left: 0;
    }
    &.no-gutter-md-left {
      padding-left: 0;
    }
    &.no-gutter-md-right {
      padding-right: 0;
    }
    &.reset-gutters-md {
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
    }
  }
  .row {
    &.no-gutter-md {
      margin-right: 0;
      margin-left: 0;
    }
    &.no-gutter-md-left {
      margin-left: 0;
    }
    &.no-gutter-md-right {
      margin-right: 0;
    }
    &.reset-gutters-md {
      margin-right: ($grid-gutter-width / 2);
      margin-left: ($grid-gutter-width / 2);
    }
  }
}

@media (min-width: $screen-lg-min) {
  *[class*='col-'], .container, .container-fluid {
    &.no-gutter-lg {
      padding-right: 0;
      padding-left: 0;
    }
    &.no-gutter-lg-left {
      padding-left: 0;
    }
    &.no-gutter-lg-right {
      padding-right: 0;
    }
    &.reset-gutters-lg {
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
    }
  }
  .row {
    &.no-gutter-lg {
      margin-right: 0;
      margin-left: 0;
    }
    &.no-gutter-lg-left {
      margin-left: 0;
    }
    &.no-gutter-lg-right {
      margin-right: 0;
    }
    &.reset-gutters-lg {
      margin-right: ($grid-gutter-width / 2);
      margin-left: ($grid-gutter-width / 2);
    }
  }
}