.view-collections {
  .browsing-as {
    margin: 15px 0;
  }
  .view-prefix {
    padding: 15px 0;
    margin-top: 60px;
    margin-bottom: 60px;
    border-top: 1px solid $table-border-color;
    border-bottom: 1px solid $table-border-color;
    .controls > div {
      display: inline-block;
      margin-top: 15px;
      @media #{$tablet} {
        margin-top:0;
      }
    }
    .log-in-access {
      margin-top: 5px;
    }
    .institution-select {
      .form-item-select, select {
        width:auto;
        max-width:225px;
        margin:0;
      }
    }
    .views-pager {
      width:auto;
      margin-left: 15px;
      ul.pagination {
        margin:0;
      }

    }
  }
}

.view-featured-collections, .view-collections {
  .views-row {
    margin-bottom: 15px;
    padding-bottom: 15px;

    &.views-row-last {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    article > .left {
      display:flex;
      height: 300px;
      flex-direction: column;
      align-content: stretch;
      transition: height 1s;
      .body-teaser {
        flex-grow: 1;
        overflow:hidden;
        text-overflow: ellipsis;
        content: "";
        position:relative;
      }
      &.expandable {
        .body-teaser {
          cursor: pointer;
          > .field:before {
            content:'';
            width:100%;
            height:100%;
            position:absolute;
            left:0;
            top:0;
            background:linear-gradient(0deg, white, rgba(255, 255, 255, 0) 50px);
          }
        }
      }
      &.expandable.expand {
        display:block;
        height:auto;
        transition: height 1s;
        .body-teaser {
          cursor: pointer;
          .field:before {
            background:transparent;
          }
        }
      }
    }
  }
  .views-pager ul.pager {
    margin-top: 15px;
    padding-top: 15px;
    border-top: $table-border-color 1px solid;
    float: none;
    width: auto;
    text-align: center;
    margin-right: 0;
  }
  .view-mezzanine .views-pager ul.pager {
    border-top: none;
    margin-top:5px;
    padding-top:0;
    margin-bottom:0;
  }
  .view-footer {
    margin-top: 15px;
    @media #{$tablet} {
      margin-top:30px;
      float: left;
      width: 47%;
    }
  }

  ul.pager {
    @media #{$tablet} {
      margin-top:30px;
      float: right;
      width: 50%;
      text-align: right;
      margin-right: 20px;
    }

    .pager-current {
      padding: 0 6px;
    }
  }
}

.node-type-islandora-solr-content-type {
  article {
    .call-to-action {
      margin-top:15px;
      background-color:rgb(254,248,212);
      border-left: rgb(254,221,184) 4px solid;
      padding:15px;
    }
  }
}

/*.node-type-islandora-solr-content-type {
  .content-header {
    @media only screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}*/
