#block-bdf-solr-collections-bdf-solr-current-terms {
  .term.btn-default {
    background:#fff;
    padding:2px 4px;
    margin:2px 4px 2px 0;
    max-width:100%;

    .term-remove {
      a {
        margin-left: 4px;
      }
    }
    a {
      vertical-align: top;
    }
    cursor:inherit;
    a.term-select {
      max-width: calc(100% - 12px);
      overflow:hidden;
      text-overflow:ellipsis;
      display: inline-block;
    }
    a.term-remove {
      margin-left: 4px;
      i.text-danger {
        &:hover {
          color: lighten($brand-danger, 10%);
        }
      }
    }
  }
  #edit-term-search-submit .glyphicon {
    font-size: $font-size-base;
    line-height: $font-size-base;
  }
}
