 .text-xs-left { text-align: left; }
 .text-xs-right { text-align: right; }
 .text-xs-center { text-align: center; }
 .text-xs-justify { text-align: justify; }

 @media (min-width: $screen-sm-min) {
     .text-sm-left { text-align: left; }
     .text-sm-right { text-align: right; }
     .text-sm-center { text-align: center; }
     .text-sm-justify { text-align: justify; }
 }

 @media (min-width: $screen-md-min) {
     .text-md-left { text-align: left; }
     .text-md-right { text-align: right; }
     .text-md-center { text-align: center; }
     .text-md-justify { text-align: justify; }
 }

@media (min-width: $screen-lg-min) {
    .text-lg-left { text-align: left; }
    .text-lg-right { text-align: right; }
    .text-lg-center { text-align: center; }
    .text-lg-justify { text-align: justify; }
}