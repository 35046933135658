body > #node_hero {
  height: 180px;
}

.search-controls {
  border-top: 1px solid $table-border-color;
  border-bottom: 1px solid $table-border-color;
  padding: 15px 0 5px 0;
  margin-top: 60px;
  margin-bottom: 60px;
  .solr-search-controls {
    font-family: $headings-font-family;
    font-size: $font-size-h5;
    font-weight: $headings-font-weight;
    > * {
      display: inline-block;
      vertical-align: middle;
      margin-right: 30px;
      &:last-child {
        margin-right: 0px;
      }
    }
    ul.islandora-solr-display {
      li a {
        &.active {
          color: $link-hover-color;
        }
      }
    }
    form.sort-selector {
      .form-group {
        margin-bottom: 10px;
      }
    }
    ul.pagination, ul.pager {
      margin-bottom:9px;
    }
  }
  .log-in-access-control {
    margin-top:8px;
    @media (max-width: $screen-md-min) {
      margin-bottom: ($grid-gutter-width / 2);
      margin-top:0;
    }
  }
}
.islandora-solr-bottom {
  .log-in-access-control {
    margin-top: ($grid-gutter-width / 2);
  }
}

@mixin search_block_title() {
  font-size: $font-size-h4;
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid $table-border-color;
}

#block-islandora-solr-basic-facets .block-title {
  display:none;
}

.node-islandora-solr-content-type, .page-islandora-search {
  aside {
    .block-title, .islandora-solr-facet-wrapper {
      margin-bottom: 30px;
      > h3, > h2 {
        @include search_block_title();
      }
    }
    h2.block-title, h3.block-title {
      @include search_block_title();
    }

    ul.islandora-solr-facet {
      list-style: none outside none;
      padding-left: 0;
      li {
        padding: 7px 0;
        border-bottom: 1px solid $table-border-color;
        a {

        }
        .count {

        }
      }
    }
    section {
      margin-bottom: 0;

      &#block-bd-ui-bd-log-in-access-info {
        @media only screen and (max-width: 767px) {
          margin-bottom: 15px;
        }
      }
      > .block-title {
        margin-bottom: 30px;
      }

      &:first-child {
        > .block-title {
          margin-top: 0;
        }
      }
    }
  }
}

.form-item-term-search-text {
  input::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

.block-cf-solr-collections {
  .form-type-select {
    margin-bottom: 10px;
    height:38px;
  }

  .input-group-btn > .btn {
    padding: 11px 12px;
    height: 38px;
  }

  .input-group-btn > .btn::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

#islandora-solr-top {
  min-height: inherit;
}

.islandora-solr-bottom {
  padding-top: 15px;
  border-top: 1px solid $table-border-color;
 .text-right > .text-center {
   text-align: right;
 }
}

.islandora-solr-content {
  > * {
    margin-left: 15px;
    margin-bottom: 15px;
  }
  > .islandora-solr-search-results {
    margin-left: inherit;
  }
  .search-results-login-access-above, .search-results-login-access-below {
    margin-bottom: 20px;
  }

  .islandora-solr-search-results.list-view {
    .islandora-solr-search-result {
      margin-bottom:30px;
      padding-bottom:30px;
      border-bottom: 1px solid $table-border-color;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

// Style list display
.islandora-solr-search-results {
  div.islandora-inline-metadata {
    .solr-label.fgs-label-s, .solr-label.mods-abstract-s {
      display: none;
    }
    .solr-value {
      &.fgs-label-s, &.mods-abstract-s {
        margin-bottom: ($grid-gutter-width / 2);
      }
    }
    .solr-value.mods-abstract-s {
      display: block;
      display: -webkit-box;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      //max-height: (14px * 1.3 * 3);
      //font-size: 14px;
      line-height: 1.5;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

    }
    .solr-value.fgs-label-s {
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;
      line-height: $headings-line-height;
      font-size: $font-size-h4;
      a {
        border-bottom:none;
      }
    }
  }
}

.login-text {
  display: inline;
}

.text-inline {
  display: inline;
}

.row.content-region {
  @media only screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.islandora-inline-metadata {
  .row {
    @media only screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.col-xs-12 .content-header .col-xs-12 {
  padding-left: 0;
  padding-right: 0;
}
