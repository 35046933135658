$tile-x: $thumbnail_column_width;
$tile-y: $thumbnail_column_width;

@mixin collapse_by_aspect {
  dt {
    //display: -webkit-flex; /* Safari */
    //display: flex;
    position:relative;
    padding-top:0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    //overflow: hidden;
    a {
      position:relative;
      img {
        //flex-shrink: 0;
      }
    }
  }
  //&.aspect-wide {
  //  dt a {
  //    max-height: 100%;
  //    img {
  //      height:$tile-y;
  //      max-height: 100%;
  //      max-width: none;
  //    }
  //  }
  //}
  //&.aspect-high {
  //  dt a {
  //    max-width: 100%;
  //    img {
  //      width:$tile-x;
  //      max-width: 100%;
  //      max-height: none;
  //    }
  //  }
  //}
    dt a {
      max-width: 100%;
      img {
        width:$tile-x;
        max-width: 100%;
        max-height: none;
      }
    }

}

@mixin cit-crop-both() {
  height: $tile-y;
  width: $tile-x;
}

@mixin cit-crop-horizontal($h) {
  height: $tile-y;
  width: $tile-x * $h;
}

@mixin cit-crop-vertical($v) {
  height: $tile-y * $v;
  width: $tile-x;
}


.islandora-basic-collection {
  overflow:visible;
}
.islandora-basic-collection-object.islandora-basic-collection-list-item .soft-crop,
.islandora-basic-collection-object.soft-crop {

      @include collapse_by_aspect;

      > dt {
        //@include cit-crop-both;
        //&:hover {
        //  overflow: visible;
        //  z-index:20;
        //}
  }
}


.islandora-basic-collection-object.islandora-basic-collection-list-item .hard-crop,
.islandora-basic-collection-object.hard-crop {

  @include collapse_by_aspect;

  dt:hover {
    overflow: visible;
    z-index:20;
  }

  &.tile-crop-1hx1w { dt {@include cit-crop-both;} }

  &.tile-crop-1hx2w { dt {@include cit-crop-horizontal(2);} }
  &.tile-crop-1hx3w { dt {@include cit-crop-horizontal(3);} }
  &.tile-crop-1hx4w { dt {@include cit-crop-horizontal(4);} }
  &.tile-crop-1hx5w { dt {@include cit-crop-horizontal(5);} }
  &.tile-crop-1hx6w { dt {@include cit-crop-horizontal(6);} }
  &.tile-crop-1hx7w { dt {@include cit-crop-horizontal(7);} }
  &.tile-crop-1hx8w { dt {@include cit-crop-horizontal(8);} }
  &.tile-crop-1hx9w { dt {@include cit-crop-horizontal(9);} }
  &.tile-crop-1hx10w { dt {@include cit-crop-horizontal(10);} }

  &.tile-crop-2hx1w { dt {@include cit-crop-vertical(2);} }
  &.tile-crop-3hx1w { dt {@include cit-crop-vertical(3);} }
  &.tile-crop-4hx1w { dt {@include cit-crop-vertical(4);} }
  &.tile-crop-5hx1w { dt {@include cit-crop-vertical(5);} }
  &.tile-crop-6hx1w { dt {@include cit-crop-vertical(6);} }
  &.tile-crop-7hx1w { dt {@include cit-crop-vertical(7);} }
  &.tile-crop-8hx1w { dt {@include cit-crop-vertical(8);} }
  &.tile-crop-9hx1w { dt {@include cit-crop-vertical(9);} }
  &.tile-crop-10hx1w { dt {@include cit-crop-vertical(10);} }

}


.solr-thumb, .solr-masonry-thumb {
  position:relative;
  & > div {
    max-width:100%;
    position: relative;
    border-radius: $border-radius-small;
    overflow: hidden;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    &:hover {
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.7);
    }
  }

  // We're showing the default content model thumbnail.
  .fa.default-thumbnail {
    position: relative;
    width: 100%;
    text-align:center;
    padding-top: 30%;
    padding-bottom: 30%;
    color: lighten($link_color, 30%);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: $accent-low;
    &:hover {
      color: lighten($link_color, 20%);
    }
    &:before {
      font-size: floor(($font-size-base * 5.5)); // ~77px
    }
    & > a {
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
    }
  }

  // Show content model icon as an overlay for objects with thumbnails.
  .fa.datastream-thumbnail {
    &:before {
      pointer-events: none;
      position: absolute;
      right: 5px;
      bottom: 5px;
      padding: 4px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
      background: rgba(255, 255, 255, 0.6);
      font-size: $font-size-large; // 18px
    }
    a img {
      border-radius: $border-radius-small;
      //border: 1px solid lighten($link_color, 30%);
      border:none;
    }
  }
}

// There has to be a better way to get rid of this gap.
.solr-masonry-thumb, .solr-thumb {
  .fa.datastream-thumbnail {
    margin-bottom: -2px;
    i.embargo-icon {
      pointer-events: none;
      position: absolute;
      right: 35px;
      bottom: 5px;
      padding: 4px 6px;
      background-color: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
      color: #000;
      font-size: $font-size-large; // 18px
    }
  }
}

// Deal with embargoed
.solr-thumb, .solr-masonry-thumb {
  .embargoed.denied {
    width: 100%;
    // Hack!
    height: 165px;
    a {
      width: 100%;
      text-align:center;
      padding-top: 30%;
      padding-bottom: 30%;
      color: lighten($link_color, 30%);
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      &:hover {
        color: lighten($link_color, 20%);
      }
      div {
        font-size: floor(($font-size-base * 5.5)); // ~77px
      }
      &:after {
        content: none !important;
      }
    }
  }
}
.solr-masonry-field-inner {
  position:relative;
}