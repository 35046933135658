@mixin clearfix {
  content: ' ';
  display: block;
  clear: both;
}

$masonry-caption-font-size: 16px;
$masonry-caption-line-height: 1.3;
$masonry-caption-lines-to-show: 3;


.islandora-solr-content, .islandora-solr-search-results {
  .loading {
    text-align: center;
    margin-top: 150px;
    opacity: 0.5;
  }
  position:relative;
  .solr-masonry-field {
    width:25%;
    padding-left:20px;
    min-width:$thumbnail_column_width;
    height:auto;
    margin-bottom: 30px;
    .solr-masonry-thumb a img {
      width:100%
    }
    .solr-masonry-thumb:hover + .solr-masonry-caption a {
      color: $link-hover-color;
    }
    .solr-masonry-caption {
      display: block;
      max-height: $masonry-caption-font-size*$masonry-caption-line-height*$masonry-caption-lines-to-show;
      //margin: 0 auto;
      font-size: $masonry-caption-font-size;
      line-height: $masonry-caption-line-height;
      //-webkit-line-clamp: $lines-to-show;
      //-webkit-box-orient: vertical;
      //overflow: hidden;
      //text-overflow: ellipsis;
    }
  }
  .islandora-solr-masonry {
    margin-left: -$grid-gutter-width;

     @media only screen and (max-width: 767px) {
       margin-left: -15px;
     }

    > * {
      opacity:0;
      &.loading {
        opacity:0.5;
      }
    }
    &.masonry-processed {
      > * {
        opacity:1;
        -webkit-transition: opacity 1s; /* Safari */
        transition: opacity 1s;
        &.loading {
          opacity:0;
          -webkit-transition: opacity 500ms; /* Safari */
          transition: opacity 500ms;
        }
      }
    }
  }
}
