//.vertical-center {
//  display: inline-block;
//  vertical-align: middle;
//  float: none;
//}

.region-content, #block-islandora-solr-basic-facets, .modal-body {
  a {
    border-bottom: 1px dotted $link-color;
    padding-bottom: .09em;
    &:hover {
      border-bottom-color: $link-hover-color;
    }
  }
  a.btn, .webform-link a {
    border-bottom: none;
    padding-bottom: 6px;
  }
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6,
  .solr-search-controls, .pager, .solr-thumb {
    a {
      border-bottom: none;
    }
  }
}


.row.vertical-align-bottom {
  font-size: 0;
  & > * {
    float: none;
    display: inline-block;
    font-size: $font-size-base;
  }
}

.d-inline-block {
  display: inline-block;
}

.menu.navbar-nav {
  //margin: ($navbar-padding-vertical / 2) (-$navbar-padding-horizontal);

  > li.first > a {
    padding-left: 0;
  }
}

body {
  > .container, > .container-fluid > .container {
    max-width: 940px;
  }
  .not-front {
  }
}

// Global - Header styles
#page-header-container {
  background: $accent-low;
  padding-top: 15px;
  .header.container {
    padding-bottom:10px;
    > .row {
      font-size: 0;
      position: relative;
      height:120px;
      @media (max-width: $screen-sm) {
        height:150px;
      }
      @media (max-width: 540px) {
        height:180px;
      }
      > * {
        position: absolute;
        bottom: 15px;
        float: none;
        display: inline-block;
        font-size: $font-size-base;
      }
      .logo-title {
        left: 0;
        @media (max-width: $screen-sm) {
          top: 0;
          bottom: inherit;
        }
      }
      .user-links {
        right: 0;
        @media (max-width: $screen-sm) {
          right: inherit;
          left: 0;
        }
      }
    }
  }
  .logo-container {
    margin-left:-15px;
    line-height: 34px;

    a img {
      display: inline-block;
      &.site-title {
        margin-right: 1em;
      }
    }
  }
  .main-nav {
    border-top: 2px solid $accent-med;
  }
  .user-links {
    .user-info {
      white-space:nowrap;
      clear:both;
      margin-bottom: 10px;
      margin-top:15px;
      text-align: right;
    }
    .row-two > * {
      display:inline-block;
      margin-left:auto;
      margin-right: 0;
    }
    .row-two {
      clear:both;
    }
    .btn.logout, .btn.login {
      width:150px;
      clear:both;
    }
    .row-two .partner-institution-logos {
      img {
        margin-top: 7px;
        margin-bottom: 7px;
      }
    }

    // Mobile adjustments for user links
    @media (max-width: $screen-sm) {
      text-align: left;
      float: left;
      > * {
        float:left;
      }
      .row-two > * {
        margin-left: 0;
        margin-right: auto;
      }
    }

  }

  .user-links {

    .user-info, .why-log-in {
      font-weight: bold;
    }

    a.login, a.logout {
      min-width:150px;
      text-align:center;
    }
  }
}

body > .main-container {
  margin-top: 15px;
  .page-header {
    border-bottom: none;
  }

}

.node > .content > * {
  margin-bottom: 20px;
}

.field.field-name-isct-featured-content-area.field-type-text-long.field-label-hidden {
  padding: 10px;
  border: 1px solid $gray-medium;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;
}

#global-search {
  background: #D8D5CF;
  box-shadow: inset 0 0 3px #BBB8B2;
  position: relative;
  padding: 0;
  //margin-bottom:20px;
  //height: 90px;
  #global-search-widget {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.hero {

    background-color: transparent;
    height: 400px;

    @media (max-width: $screen-md) {
      height: $hero-banner-image-height;
    }

    #global-search-widget {
      padding-top: 30px;
      position: relative;
    }
  }
  > .container {
    height: 100%;
    position: relative;
  }
  .feature-caption {
    width:100%;

    position: absolute;
    bottom: ($grid-gutter-width/2);
    text-shadow: 1px, 1px, 1px, #000000;
    color: #FFFFFF;
    a {
      color: #FFFFFF;
      &:hover {
        color: #FFFF88;
      }
    ;
    }
  }
}

body > footer {
  background-color: $accent-low;
  min-height:100px;
  padding-top: 35px;
  margin-top:40px;
  border-top: 1px solid #BBB8B2;
  // Footer colors are backwards!
  color: $link-color;
  a {
    color: $headings-color;
    &:hover {
      color: lighten($headings-color, 40%);
    }
  }
}

.feature-caption {
  font-family: $font-family-serif;
  font-style:italic;
  font-size: $font-size-small;
  a {
    font-weight:bold;

  }
}

// Modal dialog styles
a.modal-toggle {
  padding:0 3px;
}
.bd-modal {
  display: inline-block;
  div[role="document"] {
    color: initial;
    background:#ffffff;
    max-width:600px;
    margin:10% auto;
    border: 1px solid $gray-light;
    box-shadow: 2px 2px 8px #000000;
    border-radius: 4px;
  }
}

// Both navbar header and collapse
//
// When a container is present, change the behavior of the header and collapse.

.container,
.container-fluid {
  .col-xs-12.main-container,
  .row .navbar-header,
  .row .navbar-collapse,
  .row #global-search-widget,
  .row section.col-sm-12.content-column,
  .row.content-header > .col-xs-12 {
    @media (min-width: $grid-float-breakpoint) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}

.btn {
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-h5, floor(($font-size-h5 * $line-height-base)), $btn-border-radius-base);
}

.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger
{
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-xsmall, $font-size-xsmall, $btn-border-radius-base);
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #000;
  font-family: $btn-font-family;
  font-weight: 700;
  letter-spacing: 1px;

  a {
    color: #fff;
  }
}

.form-control:focus {
  border-color: $gray-medium;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(10,10,8, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(10,10,8, 0.3);
}

.node-type-page .field-name-field-show-view .field-item {
  margin-top: 30px;
}

.ellipis {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.vcenter {
  //display: inline-block;
  //vertical-align: middle;
  //float: none;
  display: flex;
  align-items: center;
}

.fa.fa-question-circle {
  font-size: 0;

  &::before {
    font-size: 16px;
  }
}

// In case the modal link block is wrapped in .text-nowrap
.modal div[role="document"] {
  white-space:normal;
  // TODO: figure out how to make the modal-body vertically scroll
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.logo-title {
  white-space: normal;
}

.logo-title-link {
  font-family: $headings-font-family;
  font-size: 24px;
  font-weight: bold;
  color: $text-color;
  white-space: nowrap;
  display: inline-block;
  margin-right: 16px;

  &:hover {
    color: lighten($text-color, 10%);
  }
}

.logo-subtitle {
  font-family: "Noto Serif";
  font-size: 24px;
  font-style: italic;
  color: #93876F;
  font-weight: 400;
  display: inline-block;
  white-space: nowrap;
}

.modal-link-wrapper {
  .btn {
    padding-top: 2px;
  }
}

.modal-body-wrapper {
  text-align: left;
}

.user-info.why-log-in {
  .modal-toggle {
    padding-right: 0;
  }
}

ul.pagination, ul.pager {
  li.pager-current {
    color: $link-hover-color;
  }
}

#global-search.hero.global-search {
  height: 200px;
}

div#BRpage {
  padding-left: 0px;
}

.tooltip-info {
  border-bottom: dotted 1px $gray-light;
}