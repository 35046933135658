.ctools-auto-submit-click {
  display:none;
}

.field-name-field-ci-address {
  margin-bottom: 15px;
}

.node-type-contact-form {

  .page-header {
    padding-bottom: 0;
  }

  .paragraphs-item-contact-block {
    margin-top: 30px;

    @media #{$tablet} {
      margin-left: 30px;
      margin-top: 0;
    }
  }
}

.node-contact-form {

  .form-control {
    border: solid 1px $input-border;
  }

  .webform-client-form {
    margin-top: 60px;
  }

  .form-item {
    margin-top: 25px;
    margin-bottom: 0;
  }

  .captcha {
    margin-top: 25px;
    margin-bottom: 0;
  }

  .btn-primary {
    width: 100%;
    margin-top: 25px;
  }
}

.page-contact #block-system-main {

  .form-control {
    border: solid 1px $input-border;
  }

  .btn {
    @extend .btn-primary;

    min-width: 50%;
    @media (max-width: $screen-xs) {
      width: 100%;
    }
  }
}
