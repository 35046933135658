// Compound object navigation JAIL display
.jail-wrapper {
  position: relative;
  margin-top: 10px;

  > * {
    display: inline-block;
  }
  > .compound-jail-nav-arrow {
    position: absolute;
    top:40%;
    color: $link-color;
    &:hover {
      color: $link-hover-color;
      cursor: pointer;
    }
  }
  .compound-jail-nav-arrow-left {
    left: 0px;
  }
  .compound-jail-nav-arrow-right {
    right: 0px;
  }
}
#block-islandora-compound-object-compound-jail-display {
  margin: 0 30px;
  width: calc(100% - 60px);
  height: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  position:relative;
  .navigation-item {
    max-height: 120px;
    width: 100px;
    transition: box-shadow 0.3s;
    overflow:hidden;
    display: inline-block;
    box-shadow: 1px 1px 4px $link-color;
    margin: 8px 10px;
    border: 3px solid #fff;

    &:hover {
      box-shadow: 2px 2px 8px #000;
      transition: box-shadow 0.3s;
    }
    &.active {
      box-shadow: 2px 2px 8px #000;
      border: 3px solid #000;
      pointer-events: none;
    }
  }

  .object-nav {
    margin-bottom: 1em;
    .container-links {
      display: inline-block;
      a {
        display: inline-block;
        margin-right: 10px;
      }
    }
    .prev-next-nav {
      float: right;
    }
  }
}

