body.page-islandora-object {

  .islandora-object-metadata {
    .tab-content {
      margin-top: 20px;
    }
    .nav > li > a {
      @media(max-width: $screen-xs) {
        padding: 10px 10px;
      }
    }
  }
  // Description
  .islandora-solr-metadata-description {
    margin-top: ($grid-gutter-width/2);
  }
  // Metadata table
  div.islandora-metadata-display {
    .islandora-inline-metadata {
      .metadata-row {
        margin-bottom: ($grid-gutter-width/3);
        padding-bottom: ($grid-gutter-width/3);
        border-bottom: 1px solid $table-border-color;
        .field-label {
          overflow:hidden;
        }
      }
    }
  }
  .islandora-object-content-wrapper {
    margin-bottom: $grid-gutter-width;
  }
  .object-links {
    margin-bottom: $grid-gutter-width;
    .left > *, .right > * {
      margin-bottom: ($grid-gutter-width/3);
    }

    .left {
      padding-right: 15px;
    }

    .right {
      padding-left: 15px;
    }

    .permalink {
      .permalink-value {
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        display:inline-block;
        //width: calc(100% - 5.4em);
        width: calc(100% - 8.4em);
        vertical-align: bottom;
      }
      .permalink-label {
        font-weight: bold;
        width: 5.2em;
      }
    }
    #ds_download_widget_wrapper {
      position:relative;
      #ds_download_widget_popup_wrapper {
        margin-top: 10px;
        z-index:100;
        margin-top:$grid-gutter-width / 2;
        #ds_download_widget_popup {
          position:relative;
          padding: $grid-gutter-width / 2;
          background-color: #eee;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
          .ds-download-popup-label {
            margin-top:0;
          }
          .ds-download-terms-of-use {
            margin-top: 10px;
            font-size: $font-size-small;
          }
          #ds_download_popup_close {
            position: absolute;
            top: 5px;
            right:10px;
            color: $link-color;
            &:hover {
              cursor: pointer;
              color: $link-hover-color;
            }
          }
        }
      }
    }
    .webform-links {
      &.col-sm-6 {
        margin-top: $grid-gutter-width;

        @media #{$tablet} {
          margin-top: 0;
        }
      }

      #islandora_webform_object_tag_links {
        .webform-links-wrapper {
          padding: 0;
          margin: 0;
          &.opened {
            padding: 0;
            margin: 0;
            border: none;
            //background-color: $accent-med;
          }
        }
        .webform-link-label {
          font-weight:bold;
          margin-bottom: ($grid-gutter-width/3);
        }
        .webform-link {
          a {
            //@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
            //@include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
            //text-transform: uppercase;
            @extend .btn;
            @extend .btn-primary;
            @extend .btn-sm;
            min-width: 50%;
            @media (max-width: $screen-xs) {
              width: 100%;
            }
            text-align: center;
            display: inline-block;
          }
        }
      }
      &.webform-link-cols-2 #islandora_webform_object_tag_links {
        .webform-links-wrapper {
          width:50%;
          min-height: 1px;
          padding-right: floor(($grid-gutter-width / 2));
          float:left;
        }
      }
    }
  }
  #bd_inline_webform_target {
    form {
      border: $table-border-color solid 1px;
      padding: ($grid-gutter-width/2);
      margin-bottom:$grid-gutter-width;
      h2.object-label {
        font-size: $font-size-h4;
        margin-top: 0;
      }

    }
  }
  .islandora-newspaper-content .vertical-tabs {
    ul.vertical-tabs-list {
      display: flex;
      flex-wrap: wrap;
      li.vertical-tab-button.active > a {
        border-right-color: #ddd;
      }
      li.vertical-tab-button > a {
        border-radius: 4px;
        padding: 6px 8px;
        &:hover {
          border-color: #fff;
        }
      }
    }
    fieldset.collapsible {
      legend.panel-heading a.panel-title {
        border-bottom: none;
      }
      .panel-body {
        &.collapsed {
          padding-top: 0;
          padding-bottom: 0;
          * {
            visibility: hidden;
            height: 0;
          }
        }
      }
    }
  }
}

.page-islandora-object .region-content {
  .embargo-lift-msg {
    opacity: .6;
  }

}
// Viewers
.page-islandora-object .region-content .islandora-object-content {
    width: 100%;
  &.islandora-basic-image-content {
    a img {
      width:100%;
    }
  }
  .embargo-msg {
    max-width:400px;
    padding:30px;
    border: 1px solid $table-border-color;
    margin-bottom:40px;
    .h2 {
      margin-top:0;
    }
    .embargo-lift-msg {
      display:block;
      margin-top:15px;
      font-size: $font-size-xsmall;
      opacity: .6;
    }

    .embargo-msg-login {
      margin-top:15px;
    }
  }
}

// Metadata
.mods-accesscondition-use-and-reproduction-s {
  @include word-wrap;
}

.page-islandora-object,
.page-islandora-webform {
  .webform-submit {
    min-width: 50%;
    @media (max-width: $screen-xs) {
      width: 100%;
    }
  }
}

.video-js {
  width: auto !important;
}

div#BRpage {
  padding-left: 0px;
}

.islandora-object-content {
  > .islandora-solr-metadata-description, > .embargo-info, > .part-title {
    margin-top: 15px;
    + .part-title {
      padding-top: 15px;
      border-top: 1px solid $gray-light;
    }
  }
}

// Override IA Bookreader search highlight.
.BookReaderSearchHilite {
  opacity: .7;
  background-color: #4A90E2;
  padding: 4px;
  margin-top: 0px;
  mix-blend-mode: multiply;
}