//
// Pagination (multiple pages)
// --------------------------------------------------
ul.pagination, ul.pager {
  margin: 0 0 5px 0;
  font-weight: $headings-font-weight;

  > li {
    > a,
    > span {
      font-family: $headings-font-family;
      font-weight: bold;
      padding: 0 $padding-base-vertical;

    }
    &.active {
      > span, > a {
        color: $link-hover-color;
        &:hover {
          color: $link-hover-color;
        }
      }
    }
    &.pager-ellipsis span {
      font-weight: inherit;
    }
  }

}
